.svg-icon {
  width: 30px;
  height: 30px;
  display: inline-block;

  &.instagram     { background-image: url(images/icons/instagram.svg); }
  &.github        { background-image: url(images/icons/github.svg); }
  &.rss           { background-image: url(images/icons/rss.svg); }

}
